export interface FullCallDetailsDTO {
  quickRecap: string;
  topicSummaries: TopicSummaryDTO[];
  nextSteps: string[];
  customerSentiment: CustomerSentimentEnum;
  salesPotential: number;
  attendees: AttendeeDTO[];
  summaryRating: SummaryRatingEnum;
  duration: number;
  transcript: string;
  callStartTime: Date;
  direction: CallDirectionTypeEnum;
  clientNumber: number;
  zoomType: ZoomTypeEnum;
  createdAt: Date;
  emailSubject: string;
  emailBody: string;
}

export interface TopicSummaryDTO {
  topic: string;
  summary: string;
}

export interface AttendeeDTO {
  name: string;
  phone: string;
  type: AttendeeTypeEnum;
}

export type CustomerSentimentEnum =
  | "POSITIVE"
  | "NEGATIVE"
  | "NEUTRAL"
  | "INCONCLUSIVE";
export const CustomerSentimentEnum = {
  Positive: "POSITIVE" as CustomerSentimentEnum,
  Negative: "NEGATIVE" as CustomerSentimentEnum,
  Neutral: "NEUTRAL" as CustomerSentimentEnum,
  Inconclusive: "INCONCLUSIVE" as CustomerSentimentEnum,
};

export type AttendeeTypeEnum = "HOST" | "PARTICIPANT";
export const AttendeeTypeEnum = {
  Host: "HOST" as AttendeeTypeEnum,
  Participant: "PARTICIPANT" as AttendeeTypeEnum,
};

export type CallDirectionTypeEnum = "INBOUND" | "OUTBOUND";
export const CallDirectionTypeEnum = {
  Inbound: "INBOUND" as CallDirectionTypeEnum,
  Outbound: "OUTBOUND" as CallDirectionTypeEnum,
};

export type SummaryRatingEnum = "THUMBS_UP" | "THUMBS_DOWN" | "NOT_SET";
export const SummaryRatingEnum = {
  ThumbsUp: "THUMBS_UP" as SummaryRatingEnum,
  ThumbsDown: "THUMBS_DOWN" as SummaryRatingEnum,
  NotSet: "NOT_SET" as SummaryRatingEnum,
};

export type ZoomTypeEnum = "PHONE_CALL" | "ZOOM_MEETING";
export const ZoomTypeEnum = {
  PhoneCall: "PHONE_CALL" as ZoomTypeEnum,
  ZoomMeeting: "ZOOM_MEETING" as ZoomTypeEnum,
};
